import OneBusinessTheme from "./OneBusinessTheme";
import BritishMonomarksTheme from "./BritishMonomarksTheme";
import RegisteredAgentTheme from "./RegisteredAgentTheme";
import SmartFormationsLtdTheme from "./SmartFormationsLtdTheme";

export const themes = {
    'OneBusiness': OneBusinessTheme,
    'BritishMonomarks': BritishMonomarksTheme,
    'SmartFormationsLtd': SmartFormationsLtdTheme,
    'RegisteredAgent': RegisteredAgentTheme,
};
export default function ThemeResolver(clientName) {
    const themeFunction = themes[clientName];
    const theme = themeFunction ? themeFunction() : OneBusinessTheme();
    return theme;
}
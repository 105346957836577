import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import {
    ChevronLeft, ChevronRight,
} from "@mui/icons-material";
import {useMediaQuery} from "@mui/material";
import {LoginMenu} from "../api-authorization/LoginMenu";
import {useContext, useState} from "react";
import TenantMenu from "./NavMenu/TenantMenu";
import AuthContext from "../api-authorization/AuthContext";
import AccountMenu from "./NavMenu/AccountMenu";
import {ROLES} from "../enums";
import ClientMenu from "./NavMenu/ClientMenu";

const drawerWidth = 240;
const miniDrawerWidth = 86;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: theme.spacing(2), // Consistent marginLeft for small screens.
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: theme.spacing(1), // increased marginLeft when menu is open
        }),
        [theme.breakpoints.down('sm')]: { // When screen is small (up to sm), keep marginLeft less
            marginLeft: open ? theme.spacing(25) : theme.spacing(7)
        },
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: "100%",
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: { // When screen is medium or larger
        width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
        marginLeft: open ? `${drawerWidth}px` : undefined,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


export default function Layout({children}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(!isMobile);
    const { user } = useContext(AuthContext);
    const [isMenuBusy] = useState(false);
    
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        setOpen(!isMobile);
    }, [isMobile]);



    let content;

    let subMenu;
    if(user?.needsToConfirmAccountDetails === true){
        subMenu = <></>
    }
    else if(user?.role === ROLES.TENANT_ADMIN || user?.role === ROLES.TENANT_STANDARD){
        subMenu = <TenantMenu open={open} setOpen={setOpen} isMobile={isMobile}/>;
    }
    else if(user?.role === ROLES.CLIENT_ADMIN || user?.role === ROLES.CLIENT_STANDARD){
        subMenu = <ClientMenu open={open} setOpen={setOpen} isMobile={isMobile}/>;
    }
    else if(user?.role === ROLES.ACCOUNT_ADMIN || user?.role === ROLES.ACCOUNT_STANDARD) {
        subMenu = <AccountMenu open={open} setOpen={setOpen} isMobile={isMobile}/>;
    }
    content = (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
                    >
                    </Typography>
                    <Box sx={{ display: { xs: 'block', sm: 'block' } }}>
                        <LoginMenu/>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: { sm: open ? drawerWidth : miniDrawerWidth },
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        overflow: 'visible',
                        width: { sm: open ? drawerWidth : miniDrawerWidth },
                        transition: theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
                open={open}
            >
                <DrawerHeader
                >
                    <Box display="flex" alignItems="center" justifyContent="center" sx={{
                        width: '100%',
                        pl: open ? 0 : 2.5,
                        pt: 2,
                        mt: theme.spacing(2)}}>
                        {
                            open
                                ?
                                <img alt={"One Business"} src={theme.logoUrl} width={theme.menuLogoWidth} />
                                : (
                                    <img alt={"One Business"} src={theme.miniLogoUrl} width={isMobile ? "20px" : theme.menuMiniLogoWidth}/>
                                )
                        }
                    </Box>
                    <IconButton
                        onClick={(e) => {
                            if(open) {
                                handleDrawerClose();
                            }
                            else {
                                handleDrawerOpen();
                            }
                        }}
                        sx={{
                        marginTop:'70px',    
                        marginLeft: 'auto',
                        marginBottom: '-20px',
                        marginRight: '-25px',
                        backgroundColor: open ? theme.chevronOpenColour : theme.chevronCloseColour ,
                        zIndex: 1, 
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                            "&:hover": {
                            backgroundColor: '#e8e4e4'
                        }
                    }}>
                        {
                            open
                                ?
                                <ChevronLeft style={{ color: "#082874" }}/>
                                : (
                                    <ChevronRight style={{ color: "#ffffff" }}/>
                                )
                        }
                        
                        
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List sx={{pt:4}}>
                    {subMenu}
                </List>
                <Divider />
            </Drawer>
            
                <Main open={open}>
                    <DrawerHeader/>
                    {children}
                </Main>
        </Box>

    );

    return (
        <>
            {content}
        </>
    );
}
import * as React from 'react';
import {
    AdminPanelSettingsOutlined, Dashboard, ExpandLess, ExpandMore,
    ManageAccountsOutlined, PersonAddAltOutlined, PersonSearchOutlined,
    Search, SupportAgentOutlined
} from "@mui/icons-material";
import {
    ListItem, ListItemButton, ListItemIcon, ListItemText,
    Typography, Collapse, List, Box
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useContext, useState, useCallback } from "react";
import NavigationContext from "../NavigationContext";
import AuthContext from "../../api-authorization/AuthContext";

export default function ClientMenu({ open, setOpen, isMobile }) {
    const { freshHistNavigate } = useContext(NavigationContext);
    const theme = useTheme();
    const [expandedMenu, setExpandedMenu] = useState(null); // State to track the currently expanded menu

    const navigate = useNavigate();

    const handleToggle = (menu) => {
        setExpandedMenu((prevMenu) => (prevMenu === menu ? null : menu));
    };

    const handleNavigation = useCallback((path, title) => (e) => {
        e.preventDefault();
        if (isMobile) {
            setOpen(false);
        }
        freshHistNavigate(path, title);
    }, [isMobile, setOpen, freshHistNavigate]);

    return (
        <Box display="flex" flexDirection="column" sx={{ height: '90vh' }}>
            <Box>
                <ListItem key="Home" disablePadding>
                    <ListItemButton
                        sx={{ minHeight: 48, justifyContent: 'initial', px: 2.5 }}
                        onClick={handleNavigation("/", "Home")}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 0, justifyContent: 'initial', color: '#fff' }}>
                            <Dashboard />
                        </ListItemIcon>
                        <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Home</Typography>} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>

                <ListItem key="AddAccount" disablePadding>
                    <ListItemButton
                        sx={{ minHeight: 48, justifyContent: 'initial', px: 2.5 }}
                        onClick={handleNavigation("/Account/Create", 'Create Account')}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 0, justifyContent: 'initial', color: '#fff' }}>
                            <PersonAddAltOutlined />
                        </ListItemIcon>
                        <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Add Account</Typography>} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>

                <ListItemButton
                    sx={{ minHeight: 48, justifyContent: 'initial', px: 2.5 }}
                    onClick={(e) => {
                        e.preventDefault();
                        if (open) {
                            handleToggle('search');
                        } else {
                            handleNavigation("/Account/ListClientAccounts", 'Account Search')(e);
                        }
                    }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 0, justifyContent: 'initial', color: '#fff' }}>
                        <Search />
                    </ListItemIcon>
                    {open &&
                        <>
                            <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Search</Typography>} sx={{ opacity: open ? 1 : 0 }} />
                            {expandedMenu === 'search' ? <ExpandLess /> : <ExpandMore />}
                        </>
                    }
                </ListItemButton>

                {open &&
                    <Collapse in={expandedMenu === 'search'} timeout="auto" unmountOnExit sx={{ ml: 2 }}>
                        <List component="div" disablePadding>
                            <ListItem key="AccountSearch" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Account/ListClientAccounts", 'Account Search')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <PersonSearchOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Account Search</Typography>} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="CompanySearch" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Company/ListClientCompanies", 'Company Search')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <img width={25} height={25} src={"/menu/companySearch@3x.png"} alt="Company Search" />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Company Search</Typography>} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="companiesHouseSearch" disablePadding>
                                <ListItemButton onClick={handleNavigation("/CompaniesHouse/List", 'Companies House Search')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <img width={25} src={"/menu/companiesHouseLogo@3x.png"} alt="Companies House Search" />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Companies House Search</Typography>} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="mailSearch" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Mail/ListClientMail", 'Mail Search')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <img width={25} src={"/menu/mailSearch@3x.png"} alt="Mail Search" />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Mail Search</Typography>} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>
                }

                <ListItemButton
                    sx={{ minHeight: 48, justifyContent: 'initial', px: 2.5 }}
                    onClick={(e) => {
                        e.preventDefault();
                        if (open) {
                            handleToggle('manage');
                        } else {
                            handleNavigation("/Users/ListClientUsers", 'User Search')(e);
                        }
                    }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 0, justifyContent: 'initial', color: '#fff' }}>
                        <AdminPanelSettingsOutlined />
                    </ListItemIcon>
                    {open &&
                        <>
                            <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Manage</Typography>} sx={{ opacity: open ? 1 : 0 }} />
                            {expandedMenu === 'manage' ? <ExpandLess /> : <ExpandMore />}
                        </>
                    }
                </ListItemButton>

                {open &&
                    <Collapse in={expandedMenu === 'manage'} timeout="auto" unmountOnExit sx={{ ml: 2 }}>
                        <List component="div" disablePadding>
                            <ListItem key="UserSearch" disablePadding>
                                <ListItemButton onClick={handleNavigation("/Users/ListClientUsers", 'User Search')}>
                                    <ListItemIcon sx={{ color: '#fff' }}>
                                        <ManageAccountsOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Users</Typography>} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>
                }
                <ListItem key="support" disablePadding>
                    <ListItemButton onClick={handleNavigation("/Support", 'Support')}>
                        <ListItemIcon sx={{ color: '#fff' }}>
                            <SupportAgentOutlined />
                        </ListItemIcon>
                        <ListItemText primary={<Typography sx={{ color: 'white', fontWeight: 600, fontSize: 15 }}>Support</Typography>} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            </Box>

            {open && (
                <Box mt="auto" p={2}>
                    <Typography sx={{ color: 'white', fontWeight: 600, fontSize: 10 }}>
                        {theme.menuFooterText}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

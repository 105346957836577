import * as React from "react";
import {createTheme} from "@mui/material/styles";

export default function RegisteredAgentTheme() {

    const theme = createTheme({
        palette: {
            primary: {
                main: "#426A80",
            },
            secondary: {
                main: "#f3f3f3",
            },
            text: {
                primary: "#133D3D",
            }
        },
        typography: {
            fontFamily: 'Open Sans',
            OneBusinessHeader: {
                margin: 0,
                fontSize: "1.2rem",
                lineHeight: 1.57,
                display: "block",
                color: "white"
            },
            companyDetailsHeader: {
                WebkitTextStroke: "0.7px",
                margin: 0,
                fontSize: "0.8rem",
                lineHeight: 1.57,
                display: "block"
            },
            companyDetailsBody: {
                margin: 0,
                fontSize: "0.8rem",
                lineHeight: 1.57,
                display: "block"
            },
            basketSubtitle: {
                WebkitTextStroke: "0.4px",
                margin: 0,
                fontSize: "0.8rem",
                lineHeight: 1.57,
                display: "block"
            },
            companyMailSearchHeader: {
                margin: 0,
                fontSize: "1.3rem",
                lineHeight: 1.57,
                fontWeight:900
            },
            companyMailSearchSubHeader: {
                margin: 0,
                fontSize: "0.8rem",
                lineHeight: 1.57,
                fontWeight: 500
            },
            companyMailSearchHeavyFieldDescriptor: {
                margin: 0,
                fontSize: "0.9rem",
                fontWeight: 900,
                lineHeight: 1.57,
            },
            companyMailSearchHeavyFieldValue: {
                margin: 0,
                fontSize: "0.9rem",
                fontWeight: 500,
                lineHeight: 1.57,
            },
            companyMailSearchFieldDescriptor: {
                margin: 0,
                fontSize: "0.8rem",
                fontWeight: "strong",
                lineHeight: 1.57,
            },
            companyMailSearchFieldValue: {
                margin: 0,
                fontSize: "0.8rem",
                fontWeight: 500,
                lineHeight: 1.57,
            },
            productPriceDisplay: {
                WebkitTextStroke: "0.4px",
                margin: 0,
                fontSize: "1rem",
                lineHeight: 1.57,
                display: "block"
            },
            productVatDisplay: {
                margin: 0,
                fontSize: "0.9rem",
                display: "block"
            },
            basketSubHeader: {
                WebkitTextStroke: "0.5px",
                margin: 0,
                fontSize: "1.2rem",
                lineHeight: 1.57,
                display: "block"
            },
            basketItem: {
                WebkitTextStroke: "0.4px",
                margin: 0,
                fontSize: "0.9rem",
                lineHeight: 1.57,
                display: "block"
            },
            formHeader: {
                fontWeight: 900,
                color: "#133D3D",
                fontSize: "1.5rem"
            },
            h5: {
                fontWeight: "950",
                color: "#133D3D"
            },
            h6: {
                fontSize: "1rem",
                WebkitTextStroke: "0.7px",
                color: "#133D3D"
            },
            companyQuickSearchHeader:{
                fontSize: "1rem",
                fontWeight:"900",
                wordWrap: 'break-word'
            },
            companyQuickSearchBodyHeader:{
                fontSize: "0.7rem",
                fontWeight:"900",
                wordWrap: 'break-word'
            },
            companyQuickSearchBodyText:{
                fontSize: "0.7rem",
                fontWeight:"500",
                wordWrap: 'break-word'
            },
            companyQuickSearchSummaryLink:{
                color:"#082874",
                fontSize: "0.7rem",
                fontWeight:"700",
                cursor:"pointer",
                wordWrap: 'break-word',
                textDecoration: 'underline'
            },
            forgotPasswordLink:{
                color:"#133D3D",
                fontSize: "0.85rem",
                fontWeight:"950",
                cursor:"pointer"
            },
            formationLabelHeader:{
                fontWeight:"900"
            },
            formationLabelSubHeader:{
                fontWeight:"500"
            },
            formationSubHeader:{
                fontWeight:"500",
                fontStyle:"italic"
            }
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: "#f3f3f3",
                        color: "#1e387a",
                    }
                }
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: "#426A80",
                        color: "white",
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        WebkitTextStroke: "0.75px",
                        color: "#133D3A"
                    },
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: "#133D3D"
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        color: "#133D3D",
                        fontWeight: "550"
                    }
                }
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        fontWeight: "800",
                        color: "#133D3A"
                    },
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: "950",
                        color: "#133D3D",
                        textTransform: "none"
                    }
                }
            }
        }
    });
    theme.chevronOpenColour = "#ffffff";
    theme.chevronCloseColour = "#489982";
    theme.menuLogoWidth= 170;
    theme.menuMiniLogoWidth= 60;
    theme.inputLabelProps = { color: "grey", fontWeight: 600, fontSize: "0.9rem" };
    theme.logoUrl = "/tenantLogos/registeredAgentMenuLogo.svg";
    theme.miniLogoUrl = "/tenantLogos/registeredAgentMiniLogo.svg";
    theme.loginBackgroundImage = "/tenantLogos/britishMonomarksLoginBackground.svg";
    theme.loginLogo = "/tenantLogos/registeredAgentLogo.svg";
    theme.faviconUrl =  "/tenantLogos/registeredAgentMiniLogo.svg";
    theme.documentTitle = "Registered Agent";
    theme.termsAndConditionsLink = "https://www.britishmonomarks.co.uk/the-small-print/";
    theme.privacyPolicyLink = "https://www.britishmonomarks.co.uk/gdpr-privacy-policy/";
    theme.menuFooterText = "© 2024 One Business Apps Limited";
    
    return theme;
}